'use strict';
(function ($) {

  // Prevent adding # in url when clicking link with # as href.
  $('a[href="#"]').click(e => e.preventDefault());

  // Prevent navigation to current-menu-item
  $('.current-menu-item > a').click(e => e.preventDefault());

  // Scroll to next section icon
  $('.icon-next-section').click(function () {
    var parentSection = $(this).parent();
    var nextSection = $(parentSection).next();

    if (nextSection.length) {
      $('html').animate({
        scrollTop: (parentSection.scrollTop() + nextSection.offset().top) - $('#masthead').outerHeight(true)
      }, 700);
    }
  })

  $('#wc-close-notice').click(function () {
    $(this).closest('.woocommerce-message').remove();
  })

})(jQuery);