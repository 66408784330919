/**
 * File quiz.js.
 *
 * Handles the multi step quiz.
 */
(function () {
  // If quiz is in current page
  var quizContainer = document.getElementById('ode-quiz-container');
  if (typeof (quizContainer) != 'undefined' && quizContainer != null) {

    // Vars
    var currentTab = 0; // Current tab is set to be the first tab (0)
    var nextButton = document.getElementById('quiz-next');

    // Event Listener
    nextButton.addEventListener('mouseup', handleNextButtonClick)

    // Fn called on init
    showTab(currentTab); // Display the current tab

    // Quiz functions
    function showTab(n) {
      // This function will display the specified tab of the quiz
      var tabs = document.getElementsByClassName('tab');
      tabs[n].style.display = 'block';

      // Change question number
      document.getElementById('question-number').textContent = currentTab + 1;

      // Disabled next button if at last tab
      if (tabs[currentTab].classList.contains('last')) {
        nextButton.disabled = true;
        nextButton.removeEventListener('mouseup', handleNextButtonClick);
      }
    }

    function hideTab(n) {
      // This function will display the specified tab of the quiz
      var tabs = document.getElementsByClassName('tab');
      tabs[n].style.display = 'none';
    }

    function handleNextButtonClick() {
      var tabs = document.getElementsByClassName('tab');
      var checkedRadio = tabs[currentTab].querySelector('input[type="radio"]:checked');

      if (checkedRadio.value === 'true') {
        tabs[currentTab].querySelector('.question-action').click();
      } else {
        hideTab(currentTab);
        currentTab++;
        showTab(currentTab);
      }
    }
  }
})();